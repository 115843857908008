var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.disabled === true ? "grey" : "" }, [
    _c(
      "svg",
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
      [
        _c("path", {
          attrs: {
            d:
              "M4 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }