<template>
  <div :class="disabled === true ? 'grey' : ''">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M4 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  width: 25px;
  margin-right: 6px;
  cursor: pointer;
  fill: #1487c0;
  &:hover {
    fill: #0d597f;
  }
}
.grey {
  svg {
    fill: #d5d5d5;
    cursor: default;
    &:hover {
      fill: #d5d5d5;
    }
  }
}
</style>
